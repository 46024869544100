<template>
  <div>
    <b-container class="">
      <p class="text-primary mb-0 font-size-20">كيف حالك ؟</p>
      <h3 class="text-primary font-weight-bold">أهلا بك فى سوق العطارة</h3>
      <b-card class="bg-warning text-white iq-border-radius-10 mt-4">
        <p class="m-0 p-0 font-size-18 font-weight-bold">
          جاري مراجعة البيانات، سيتم التواصل معك فور الانتهاء
        </p>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import mainInfo from "@/mixins/mainInfo";
export default {
  components: {},
  mixins: [mainInfo],
  mounted() {
    core.index();
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    if (this.storeActive.status === "publish") {
      this.$router.push({ name: "index" });
    }
  },
};
</script>
